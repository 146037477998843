@use 'sass:math';
@import '../../assets/scss/common';
@import '../../assets/scss/variables';
@import '../../assets/scss/mixins/functions';

.c-bar {

  h2 {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-top: 1px solid black;
    border-bottom: 1px solid black;

  }
}

@include media-breakpoint-down(sm) {
  .c-bar {

    &__box {
      padding-right: 0;
      padding-left: 0;

      h2 {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
    .col-12 {
      padding-left: 0;
      padding-right: 0;
    }

  }
}

