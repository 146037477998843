@use 'sass:math';
@import '../../assets/scss/common';
@import '../../assets/scss/variables';
@import '../../assets/scss/mixins/functions';

.section-date {
  .container-fluid {
    background-color: $mountain-heaven;
    padding-top: 2rem;
  }

  h1 {
    margin-top: 2rem;
  }

  .title {
    font-size: 1.5rem;
    font-weight: 700;
  }

  .box-contact {
    display: block;
    border-left: 1px solid black;

    a {
      margin: 0.75rem 0;
      color: #212529;
    }

    .link-footer {
      position: relative;
      display: flex;
      align-items: center;
      text-decoration: none;

      img {
        display: flex;
        align-items: center;
        margin-right: 1rem;

      }

      p {
        unicode-bidi: bidi-override;
        direction: rtl;
        margin-bottom: 0;
      }
    }
  }
}

@include media-breakpoint-only(md){
  .section-date {
    .box-contact {
      p {
        font-size: rem(12px);
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .section-date {
    .box-contact {
      border-left: none;
    }
  }
}

@include media-breakpoint-down(sm) {
  .section-date {
    .container {
      padding-right: 0;
      padding-left: 0;
    }
  }
}
