@import '../../assets/scss/element/index';
@import '../../assets/scss/variables';

/* Remove border from toggler */
.navbar-toggler {
  border: 0 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 0 !important;
}

/* Lines of the Toggler */
.toggler-icon{
  width: 30px;
  height: 3px;
  background-color: #e74c3c;
  display: block;
  transition: all 0.2s;
}

/* Adds Space between the lines */
.middle-bar{
  margin: 5px auto;
}


.btn-primary {
  --bs-btn-bg: $nav-bc;
  --bs-btn-border-color: $nav-bc;
}


.offcanvas-mobile {
  max-width: 290px;
  margin-right: 0;
  margin-left: auto;

  a {
    @extend %color-font-nav;
    font-weight: 700;
  }


  .offcanvas-body {
    display: block !important;
    overflow-y: auto !important;
  }
  a.active {
      @extend %color-black;
  }


  .offcanvas-body {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 0 1rem 1rem;
    overflow-y: hidden;

    .ic-logo {
      position: relative;
      left: 0;
    }

    > div {
      display: flex;
      flex-basis: auto;
      flex-grow: 1;
      flex-shrink: 1;
      justify-content: space-between;
    }

    a {
      display: block;
      padding: 0.6rem 0;
      font-weight: 700;
      text-decoration: none;
      text-transform: uppercase;
      border-bottom: 1px solid #eee;
      @extend %color-font-dark-nav;
    }



    ul {
      width: 100%;
    }
  }

  .offcanvas-footer {
    padding: 1rem;
    padding-bottom: 0;

    .link-footer {
      position: relative;
      display: flex;
      align-items: center;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      text-decoration: none;
      border-top: 1px solid #eee;

      img {
        display: flex;
        align-items: center;
        margin-right: 1rem;

      }

      p {
        unicode-bidi: bidi-override;
        direction: rtl;
        margin-bottom: 0;
      }

    }
  }

  .footer-bottom {
    border-top: 1px solid #eee;

    a {
      display: block;
      width: 50%;
      padding-top: 0.7rem;
      padding-bottom: 0.7rem;

      &:last-child {
        text-align: right;
      }
    }
  }
}



header {
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: 3.25rem;
  background-color: $nav-bc;

  .c-nav {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__box-left {
      @extend %color-font-nav;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 900;

      .ic-logo {
        margin-right: $grid-gutter-width / 4;
      }

      a {
        display: flex;
        align-items: center;
        text-decoration: none;

        &:hover {
          @extend %color-font-nav;
        }
      }
    }
  }

  .ic-search {
    margin-left: 1.25rem;
  }

  a {
    @extend %color-font-nav;
    font-weight: 700;
  }

  .e-mail {
    display: block;
    margin: 1rem 0 0;
    font-size: 1rem;
    font-weight: 600;
    line-height: 4rem;
    text-align: center;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }

  .footer {
    display: flex;
    align-items: center;
    padding-top: 2rem;
    padding-left: 1rem;

    span {
      margin-right: 1rem;
    }

  }

  .list-social {
    display: flex;
    justify-content: space-around;
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;

    li {
      padding: 0;
    }
  }


  .c-navbar {
    &__nav {
      display: flex;
      padding: 0;
      margin: 0;
      list-style: none;
      flex-direction: row;
    }

    &__nav-item {
      a {
        margin: 0 1rem;
        text-decoration: none;
        transition: 0.5s;
        position: relative;
        display: inline-block;
      }

      a::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: black;
        bottom: 0;
        left: 0;
        transform: scaleX(0);
        transform-origin: bottom right;
        transition: transform 0.25s linear;
      }

      a:hover::after {
        transform-origin: bottom left;
        transform: scaleX(1);
      }
    }

    &__nav-item:hover {
      a {
        @extend %color-black;
        text-decoration: none;
      }
    }

    &__nav-item:not(:last-child)::after {
      content: '';
      border-right: 2px solid $nav-font;
    }
  }
}

@media (max-width: 575.98px) {
  header {
    .ic-burger-nav {
      background-color: $nav-font;
    }

    .c-nav {
      //display: block;
      text-align: center;

      &__burger {
        .btn:hover {
          color: var(--bs-btn-hover-color);
          background: transparent;
          border-color: $nav-font;

        }
      }

    }
  }
}


.dropdown-toggle {
  border: 0 !important;
}

.dropdown-toggle:focus,
.dropdown-toggle:active,
.navbar-toggler-icon:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 0 !important;
}

/* Lines of the Toggler */
.toggler-icon{
  width: 30px;
  height: 3px;
  background-color: #e74c3c;
  display: block;
  transition: all 0.2s;
}


.middle-bar{
  margin: 5px auto;
}


.dropdown-toggle.show .top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}

.dropdown-toggle.show .middle-bar {
  opacity: 0;
  filter: alpha(opacity=0);
}

.dropdown-toggle.show .bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
}

.dropdown-toggle .top-bar {
  transform: rotate(0);
}

.dropdown-toggle .middle-bar {
  opacity: 1;
  filter: alpha(opacity=100);
}

.dropdown-toggle .bottom-bar {
  transform: rotate(0);
}

.dropdown-toggle .toggler-icon {
  background-color: #777777;
}
.dropdown-toggle:after {
  display: none;
}

.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
  color: var(--bs-btn-active-color);
  background-color: transparent;
  border-color: transparent;
}

.dropdown-footer {
  padding: 16px 16px 0;
  position: absolute;
  bottom: 0;
  width: 100%;

  .link-footer {
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-decoration: none;




    img {
      display: flex;
      align-items: center;
      margin-right: 1rem;

    }

    p {
      unicode-bidi: bidi-override;
      direction: rtl;
      margin-bottom: 0;
    }

  }
  .link-footer:not(:first-child) {
    border-top: 1px solid #eee;
  }
}



.dropdown-menu {
  width: 100vw;
  height: calc(100vh - 200px);
  a.active {
    @extend %color-black
  }
  a {
    text-decoration: none;
    text-align: center;
  }
}

