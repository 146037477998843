@use 'sass:math';
@import '../../assets/scss/common';
@import '../../assets/scss/variables';
@import '../../assets/scss/mixins/functions';

.c-all-entries {

  &__box {
    > .row {
      position: relative;
      margin-bottom: 2rem;
    }
  }
  .col-lg-8 {
    padding-top: 1rem;
  }
  p {
    margin-bottom: 0;
  }
}

@include media-breakpoint-down(sm) {
  .c-all-entries {
    .row {
      margin-right: 0;
      margin-left: 0;
    }

    .container {
      padding-right: 0;
      padding-left: 0;
    }

    &__box {
      padding-right: 0;
      padding-left: 0;

    }

    .col-lg-4 {
      padding-right: 0;
      padding-left: 0;
    }

  }
}
