body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.c-not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  overflow-x: hidden;
}
